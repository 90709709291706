<script>
import { Role } from "@/router/role";

export default {
  name: "nav",

  data() {
    return {
      // + Role based authorization

      allItems: [
        {
          _name: "CSidebarNavItem",
          name: "Compose",
          to: { name: "Compose" },
          icon: "cil-pencil",
          color: "success",
          _class: "font-weight-bold",
        },
        {
          _name: "CSidebarNavItem",
          name: "Inbox",
          to: { name: "Inbox" },
          icon: "cil-inbox",
        },
        {
          _name: "CSidebarNavItem",
          name: "Starred",
          to: { name: "Starred" },
          icon: "cil-star",
        },
        {
          _name: "CSidebarNavItem",
          name: "Sent",
          to: { name: "Sent" },
          icon: "cil-paper-plane",
        },
        {
          _name: "CSidebarNavItem",
          name: "Trash",
          to: { name: "Trash" },
          icon: "cil-trash",
        },

        {
          _name: "CSidebarNavDivider",
          _class: "m-2",
        },
        {
          _name: "CSidebarNavTitle",
          _children: ["Labels"],
        },
        // {
        //   _name: "CSidebarNavItem",
        //   name: "Urgent",
        //   to: { name: "Label", params: { label: "urgent" } },
        //   icon: {
        //     name: "cil-circle",
        //     class: "text-warning",
        //   },
        //   label: true,
        // },
        // {
        //   _name: "CSidebarNavItem",
        //   name: "Important",
        //   to: { name: "Label", params: { label: "important" } },
        //   icon: {
        //     name: "cil-circle",
        //     class: "text-danger",
        //   },
        //   label: true,
        // },
        {
          _name: "CSidebarNavItem",
          name: "Waiting",
          to: { name: "Label", params: { label: "waiting" } },
          icon: {
            name: "cil-circle",
            class: "text-warning",
          },
          label: true,
        },
        {
          _name: "CSidebarNavItem",
          name: "In-Progress",
          to: { name: "Label", params: { label: "in-progress" } },
          icon: {
            name: "cil-circle",
            class: "text-info",
          },
          label: true,
        },
        {
          _name: "CSidebarNavItem",
          name: "Done",
          to: { name: "Label", params: { label: "done" } },
          icon: {
            name: "cil-circle",
            class: "text-success",
          },
          label: true,
        },

        {
          _name: "CSidebarNavItem",
          name: "Dashboard",
          to: "/",
          icon: "cil-speedometer",
          _class: "mt-auto font-weight-bold",
        },
      ],
    };
  },

  computed: {
    currentItems() {
      return this.allItems.filter((item) => {
        return (
          !item.authorize ||
          item.authorize.some((role) => this.authUserRoles.includes(role))
        );
      });
    },

    sidebarItems() {
      return [
        {
          _name: "CSidebarNav",
          _children: this.currentItems,
        },
      ];
    },
  },
};
</script>
